@import url('../../../../App.css');

.danger {
  color: var(--yellow-1);
}

.css-l4u8b9-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before, 
.css-l4u8b9-MuiInputBase-root-MuiInput-root:before,
.css-l4u8b9-MuiInputBase-root-MuiInput-root:after{
  border-bottom: 1px solid var(--white-1) !important ;
}

#cidade_selecionada-label{
  font-weight: bold;
  color: white !important;
}

#cidade_selecionada{
  font-weight: bold;
  color: white !important;
}