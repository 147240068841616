@import url('../../../App.css');

/* Sidebar Active Button */

.sidebarBtn,
.sidebarIcon,
.iconButtonMenu {
  color: var(--white-1) !important;
  font-weight: bold;
}

.expandBtn{
  padding: 0 !important;
  color: var(--white-1) !important;
}

.listText span {
  font-weight: bold;
}

.listText{
  margin-top: 6px !important;
  margin-bottom: 0px !important;
}

.activeBtn,
.activeIcon {
  background-color: var(--white-1) !important;
  color: var(--green-6) !important;
  opacity: 1 !important;
}

.activeBtn .MuiListItemText-root span,
.activeBtn .MuiListItemIcon-root .icons {
  text-decoration: none;
  font-weight: bold;
}
