@import url('../../../App.css');

/* AppBar */

.LogoImg {
  height: 3rem;
}

/* AppBar Button */

.link-login {
  text-decoration: none;
}

.link-login h3 {
  color: var(--white-1);
  display: flex;
  align-items: center;
}

.link-login h3 svg {
  margin-right: 5px;
}

.btn-account-header{
  z-index: 1;
  cursor: pointer;
}