.cards-farm {
  display: flex;
  font-weight: bold;
  text-align: center;
  flex-direction: column;
  background-color: #009951 !important;
  color: #ffffff !important;
  box-shadow: 2px 2px 5px 0px #ababab7a !important;
  border-radius: 10px !important;
  align-content: center;
  justify-content: center;
  height: 20vh;
}

.flip-card {
  width: 100%;
  height: 20vh;
  cursor: pointer;
  justify-content: flex-start;
  border-radius: 10px !important;
  box-shadow: 2px 2px 5px 0px #ababab7a !important;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}

.flip-card:focus {
  outline: 0;
}

.flip-card:hover .flip-card-inner,
.flip-card:focus .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
}

.flip-card-front {
  z-index: 2;
  display: flex;
  align-items: center;
  background: #ffffff;
  justify-content: center;
}

.flip-card-back {
  z-index: 1;
  font-weight: bold;
  transform: rotateY(180deg);
}

.gridCity {
  display: flex;
  justify-content: center;
  flex-direction: column !important;
}

.gridStatus {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 15px !important;
  align-content: flex-end;
  justify-content: flex-end;
}