@import url("../../../App.css");

/* Icon Product */
.icon-product .MuiSvgIcon-root {
  font-size: 1.5rem;
}

/* Products Card */
.card-products {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
              0px 1px 1px 0px rgba(0, 0, 0, 0.14),
              0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

/* Buttons */
.cardTemp, .btnDetails {
  background-color: #000000af !important;
  color: var(--white-1) !important;
  border-radius: 5px !important;
  font-weight: bold !important;
  border: none !important;
  width: 140px;
  height: 50px;
  z-index: 999;
  text-align: center;
}
.btnCardProducts {
  width: 98% !important;
  margin-bottom: 5px!important;
  height: 120px;
}

/* Farm Card */

.card-farm {
  box-shadow: none !important;
}
.card-farm .card-farm-content {
  justify-content: center;
  display: flex;
  align-items: center;
}

/* Titles */

.temperature {
  color: white;
  font-weight: bold;
}
.descTemp, .descIcon{
  z-index: 999;
}

/* Windmill and Components*/
.windmill {
  position: absolute;
  left: 28%;
  top: 59px;
  opacity: 0.8;
}
.wheel {
  z-index: 10;
  width: 192px;
  height: 192px;
  position: absolute;
  left: 8px;
  top: 10px;
  animation: spin 15s linear infinite;
}
.windwheel {
  position: absolute;
  width: 8px;
  height: 100px;
  background-color: #3b3b3b;
  margin-right: 10px;
  left: 92px;
  &:before {
    positioN: absolute;
    content: "";
    background-color: #3b3b3b;
    opacity: 0.7;
    width: 15px;
    height: 75px;
    left: 8px;
  }
}
.windwheel2 {
  transform: rotate(90deg);
  top: 46px;
  left: 138px;
  z-index: 40;
}
.windwheel3 {
  transform: rotate(180deg);
  top: 92px;
}
.windwheel4 {
  transform: rotate(-90deg);
  top: 46px;
  left: 46px;
  &:after {
    position: absolute;
    content: "";
    background-color: #3b3b3b;
    opacity: 0.7;
    width: 22px;
    height: 22px;
    bottom: -7px;
    left: -7px;
    transform: rotate(45deg);
  }
}

/* Roof */
.roof {
  position: absolute;
  z-index: 9;
  left: 52px;
  top: 55px;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 70px solid #df615c;
}

/* Mill */
.mill {
  position: absolute;
  left: 52px;
  top: 124px;
  width: 100px;
  height: 120px;
  background-color: #fff;  
  &:after {
    position: absolute;
    content: "";
    background-color: #3b3b3b;
    height: 50px;
    width: 40px;
    bottom: 0;
    left: 28px;
    border-radius: 40% 40% 0 0;
    border-left: 5px solid #5c4038;
  }
  &:before {
    position: absolute;
    content: "";
    background-color: #3b3b3b;
    height: 40px;
    width: 30px;
    top: 20px;
    left: 32px;
    border-radius: 18px;
    border-left: 5px solid #5c4038;
  }
}

/* Colinas */
.hills {
  width: 245px;
  height: 150px;
  background-color: #d94d16;
  position: absolute;
  top: 94px;
  left: -115px;
  opacity: 0.5;
  border-radius: 300px 300px 0 0;
  &:before, &:after {
    content: '';
    position: absolute;
    background-color: #d94d16;
    border-radius: 300px 300px 0 0;
  }
  &:before {
    width: 250px;
    height: 110px;
    bottom: 0;
    right: -120px;
  }
}

/* House */
.house {
  position: absolute;
  width: 80px;
  height: 69px;
  background-color: #fff;
  top: 175px;
  left: 152px;
  border-top: 20px solid #df615c;
  &:after {
    content: "";
    position: absolute;
    background-color: #3b3b3b;
    width: 20px;
    height: 30px;
    bottom: 0;
    left: 30px;
    border-left: 5px solid #5c4038;
  }
}

/* Bush */
.bush {
  position: relative;
  top: 203px;
  left: -80px;
}
.bush li {
  float: left;
  list-style: none;
  background-color: #33C165;
  width: 40px;
  height: 25px;
  border-radius: 100px 100px 0 0
}
.bush2 {
  top: 183px;
  left: 90px;
}
.bush2 li{
  float: left;
  list-style: none;
  background-color: #33C165;
  width: 30px;
  height: 20px;
  border-radius: 100px 100px 0 0
}

/* Tree */
.tree {
  position: absolute;
  left: -50px;
  top: 120px;
  li {
    list-style: none;
    background-color: #005700;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    margin-bottom: -15px;
  }
  &:after {
    content: "";
    position: absolute;
    background-color: #5c4038;
    width: 3px;
    height: 80px;
    top: 25px;
    right: 21px;
  }
}

/* Clouds*/
.cloud {
  position: absolute;
  top: 90px;
  left: -10px;
  background-color: #fff;
  height: 20px;
  width: 40px;
  border-radius: 40px 40px 0 0;
  opacity: 0.7;
  animation: clouds 24s linear alternate infinite;
  &:before {
    content: "";
    position: absolute;
    background-color: #fff;
    height: 30px;
    width: 60px;
    border-radius: 60px 60px 0 0;
    bottom: 0;
    right: 20px;
  }
}
.cloud2{
  height: 40px;
  width: 80px;
  top: 60px;
  animation: clouds 14s linear alternate-reverse infinite;
  &:before{
    content: "";
    position: absolute;
    background-color: #fff;
    height: 30px;
    width: 60px;
    border-radius: 60px 60px 0 0;
    bottom: 0;
    right: 40px;
  }
}

/* Responsividade  */
@media screen and (max-width: 768px) {
  .productsGrid .titles {
    font-size: 1rem;
  }

  .windmill {
    left: 120px;
    top: 60px;
  }
}

@media screen and (max-width: 360px) {
  .windmill {
    left: 90px;
    top: 60px;
  }
}

/* Animation */
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes clouds {
  100% {
    transform: translateX(145px);
  }
}
