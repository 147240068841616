.divLocal {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}

.cards-local {
  display: flex;
  font-weight: bold;
  text-align: center;
  flex-direction: column;
  background-color: #ffffff !important;
  color: #009951 !important;
  box-shadow: 2px 2px 5px 0px #ababab7a !important;
  border-radius: 10px !important;
  align-content: center;
  justify-content: center;
  height: 7vh;
}

@media screen and (max-width:900px) {
  .divLocal {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
  }
}